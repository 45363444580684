<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('logs.title')"
        :paths="[{title:$t('settings.title'),link:'settings'}]"


        :btn="false"
    />

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table  min-height-unset d-flex flex-column">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                class="row-move "
                v-if="getData"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getData"
                :fields="tableFields"

            >

            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>


  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';

import {WameedPagination,  WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import {statues as orderStatues} from "@/enums/orderStatus.enum";
import {LogActivity} from "@/enums/logActivity.enum";

export default {
  components: {
    WameedPagination,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      showModal: false,
      deleteModal: false,
      showDisabledModal: false,
      deleteModalData: null,
      modalData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('logs.action'),
          key: 'actions',
          id: 'id',
          name: 'name',
          selectedData: [],
          type: 'text',
          translate: true,
          data: LogActivity,
        },
        {
          title: this.$i18n.t('filters.date'),
          key: "Date",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "date",
          startDate: null,
          endDate: null,
        },

      ],
      tableFields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },
        {
          key: 'user_name',
          label: this.$i18n.t('logs.user_name'),
          sortable: false,
        },

        {
          key: 'action',
          label: this.$i18n.t('logs.action'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'content',
          label: this.$i18n.t('logs.content'),
          sortable: false,
          tdClass: 'w-2/5'
        },
        {
          key: 'created_at',
          label: this.$i18n.t('logs.date'),
          sortable: false,
          tdClass: 'w-1/5'
        },

      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/settings/logs/getData',
      getTotal: 'admin/settings/logs/getTotal',
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      _getData: 'admin/settings/logs/get',
    }),



    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {

      this._getData(this.filterData);
    },




    applyFilter() {
      this.filterContent.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },
    resetFilter() {
      this.filterContent.forEach((item, i) => {
        if (item.key === "Date") {
          this.filterContent[i].startDate = null;
          this.filterContent[i].endDate = null;
          this.filterData['startDate'] = null;
          this.filterData['endDate'] = null;
        } else {

          this.filterContent[i].selectedData = [];
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
